<template>
  <div>
    <button
      class="vs-button rounded-full"
      @click="toogleRoute()"
      :class="route === '/app/push/list' ? 'bg-primary text-white' : 'bg-white text-primary' "
    >
      <feather-icon
        :icon="route === '/app/push/list' ? 'PlusIcon' : 'ArrowLeftIcon' "
        class="mr-2 align-middle"
      />
      <span v-if="route === '/app/push/list'">Crear notificación</span>
      <span v-else> Listado de notificaciones </span>
    </button>

    <router-view class="mt-10"></router-view>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import FeatherIcon from "../../../components/FeatherIcon.vue";

export default Vue.extend({
  components: { FeatherIcon },
  
  created() {
    this.$store.dispatch("updatePageTitle", {
      title : 'Notificaciones',
      subtitle : 'Administrar notificaciones'
    });
  },

  methods : {
    toogleRoute() {
      this.$router.push(this.route === '/app/push/list' ? '/app/push/new' : '/app/push/list');
    }
  },
  
  computed : {
    route() {
      return this.$route.path;
    }
  },

});

</script>
